// LINK: https://nuxt.com/docs/getting-started/error-handling

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        // handle error, e.g. report to a service
        console.error("error detected: ", error)
    }

    // Also possible
    nuxtApp.hook('vue:error', (error, instance, info) => {
        // handle error, e.g. report to a service
        console.error("error detected: ", error)
    })
})
