// export default defineNuxtPlugin(nuxtApp => {
//     const MAX_RETRIES = 2;
//     const QUERY_PARAM_NAME = 'nuxt_reload_attempt'
  
//     // Handle "Failed to fetch dynamically imported module ..." or similar issues
//     nuxtApp.hook('app:chunkError', () => {
//       const searchParams = new URLSearchParams(window.location.search);
//       const currentRetry = Number(searchParams.get(QUERY_PARAM_NAME)) || 0
//       if (currentRetry < MAX_RETRIES) {
//         console.log('[nuxt]: Reloading due to chunk error')
//         searchParams.set(QUERY_PARAM_NAME, (currentRetry + 1).toString())
//         // Changing the search also causes a refresh
//         window.location.search = searchParams.toString()
//       }
//     })

//     nuxtApp.hook('page:loading:start', () => {
//       console.log("loading start from plugin")
//     })

//     nuxtApp.hook('page:loading:end', () => {
//       console.log("loading end from plugin")
//     })
//   })
export default defineNuxtPlugin({
  // eslint-disable-next-line unused-imports/no-unused-vars
  async setup(nuxtApp) {},
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    // 'page:loading:end': async function () {
    //   console.log("loading end from plugin")
    //   // await new Promise(r => setTimeout(r, 1000))
    //   // startLoading();
    // },
    // 'page:loading:start': async function () {
    //   console.log("loading start from plugin")
    //   // await new Promise(r => setTimeout(r, 1000))
    //   // startLoading();
    // },
    'app:chunkError': async function () {
      console.log("app chunk error")
      const MAX_RETRIES = 2;
      const QUERY_PARAM_NAME = 'nuxt_reload_attempt'
      const searchParams = new URLSearchParams(window.location.search);
      const currentRetry = Number(searchParams.get(QUERY_PARAM_NAME)) || 0
      if (currentRetry < MAX_RETRIES) {
        console.log('[nuxt]: Reloading due to chunk error')
        searchParams.set(QUERY_PARAM_NAME, (currentRetry + 1).toString())
        // Changing the search also causes a refresh
        window.location.search = searchParams.toString()
      }
    },
  },
});