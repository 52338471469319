import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const ORANGE_THEME: ThemeTypes = {
    name: 'ORANGE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef',
        'border-opacity': 0.4,
    },
    colors: {
        primary: '#FA896B',
        secondary: '#333333',
        info: '#539BFF',
        success: '#54d662', // '#0fd474',
        accent: '#FFAB91',
        warning: '#ffe57f',
        error: '#C54966',
        lightprimary: '#fff3f0',
        lightsecondary: '#EFF9FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f9f9f9',
        hoverColor: '#f6f9fc',
        surface: '#ffffff',
        'on-surface': '#3f3f3f',
        'on-surface-variant': '#3f3f3f',
        white: '#FFFFFF',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        grey600: '#b4b4b4',
        grey800: '#989898',
    }
};

export { ORANGE_THEME };
