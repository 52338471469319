import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const DARK_ORANGE_THEME: ThemeTypes = {
    name: 'DARK_ORANGE_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
        'border-opacity': 1,
    },
    colors: {
        primary: '#FA896B',
        secondary: '#0074BA',
        info: '#539BFF',
        success: '#13DEB9',
        accent: '#FA896B',
        warning: '#FFAE1F',
        error: '#C54966',
        lightprimary: '#402E32',
        lightsecondary: '#082E45',
        lightsuccess: '#1B3C48',
        lighterror: '#4B313D',
        lightinfo:'#223662',
        lightwarning: '#4D3A2A',
        textPrimary: '#EAEFF4',
        textSecondary: '#7C8FAC',
        borderColor: '#333F55',
        inputBorder: '#465670',
        containerBg: '#171c23',
        background: '#171c23',
        hoverColor: '#333f55',
        surface: '#171c23',
        "on-surface": '#171c23',
        'on-surface-variant': '#ffffff',
        white: '#000000',
        grey100: '#333F55',
        grey200: '#465670',
        grey600: '#909090',
        grey800: '#989898',
    }
};

export { DARK_ORANGE_THEME };
