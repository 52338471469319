import { type Pinia } from 'pinia'; 

export default defineNuxtPlugin(({ $pinia }) => {
    const _pinia = $pinia as Pinia;

    if (!_pinia) return;

    return {
        provide: {
            store: useAuthStore(_pinia)
        }
    }
})
