<script setup lang="ts">
const customizer = useCustomizerStore();
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
    <v-app :theme="customizer.actTheme">
        <div class="d-flex justify-center align-center text-center h-100">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-h1 pt-3 text-primary">¡Upps!</h1>
                    <img src="/public/images/backgrounds/errorimg.svg" width="340" alt="404" />
                    <p class="text-h4 my-8 px-14 font-weight-light text-primary">{{ $t('errors.This page you are looking for could not be found') }}</p>
                    <p><code>{{ error?.statusCode }}</code></p>
                    <p><code>{{ error }}</code></p>
                    <v-btn flat color="primary" class="mb-4" @click="() => { handleError(); navigateTo('/') }">{{ $t('Back')}}</v-btn>
                </v-col>
            </v-row>    
        </div>
    </v-app>
</template>
