import { useAuthStore } from '@/stores/auth';
// TODO: Unificar la URL base aquí, y no en cada petición por fuera. (useRuntimeConfig().public.apiBase)

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE'),
    download: request('GET', null, 'binary')
};

export const fetchAppWrapper = {
    get: request('GET', 'app'),
    post: request('POST', 'app'),
    put: request('PUT', 'app'),
    patch: request('PATCH', 'app'),
    delete: request('DELETE', 'app'),
    download: request('GET', 'app', 'binary')
};

export const anonWrapper = {
    post: request('POST', 'anon'),
};


function request(method, type, subtype) {
    return (url, body) => {
        const controller = new AbortController();
        const { signal } = controller;
        const timeout = 15000;
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        const requestOptions = {
            method,
            headers: authHeader(url, type),
            credentials: 'include'
        };
        // console.log("requestOptions: " + JSON.stringify(requestOptions));
        if (subtype !== 'binary') {
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }
            // console.info("url: ", url, ", requestOptions: ", requestOptions);
            try {
                // console.log("antes fetch: ", url, ", requestOptions: ", requestOptions);
                return fetch(url, {...requestOptions, signal })
                        .then(handleResponse)
                        .catch(error => {
                            clearTimeout(timeoutId);
                            if (error.name === 'AbortError') {
                                throw new Error('Request timed out');
                            }
                            throw error;
                        });
            } catch (err) {
                console.error("Request failed: ", err, url, requestOptions);
                throw err;
            }
        } else {
            try {
                return fetch(url, requestOptions).then(handleBinaryResponse);
            } catch (err) {
                console.error("Request failed: ", err, url, requestOptions);
                throw err;
            }
        }
    }
}


// helper functions

function authHeader(url, type) {
    // return auth header with jwt if user is logged in and request is to the api url
    // console.info(`Setting auth header to (${url}) as ` + type);
    // const isApiUrl = url.startsWith(useRuntimeConfig().public.apiBase);
    if (type === 'anon') {
        return {};
    }

    let sessionInfo = type === 'app' ? useAuthStore().appSession : useAuthStore().userSession;    
    return { Authorization: !!sessionInfo?.key ? `key ${sessionInfo.key}` : '' };
}

function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            const { userSession, logout } = useAuthStore();
            // console.log(response, text)
            if ([401, 403].includes(response.status) && userSession) {
                // console.log("auto_logout?", response)
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout('Unauthorized');
            }
            let error = {}
            try {
                const data = text && JSON.parse(text || '{}');
                error = { status: response.status, message: (data && data.message) || response.statusText };
            } catch (e) {
                error = { status: response.status, message: response.statusText };
            }
            console.error(error);
            return Promise.reject(error);
        } else {
            try {
                const data = text && JSON.parse(text);
                return data;
            } catch (e) {
                return { text }
            }
        }
    });
} 


function handleBinaryResponse(response) {
    if (!response.ok) {
        const { userSession, logout } = useAuthStore();
        if ([401, 403].includes(response.status) && userSession) {
            // console.log("auto_logout 2?", response)
            logout('Unauthorized');
        }

        const error = { status: response.status, message: response.statusText }
        return Promise.reject(error);
    }
    
    return response.arrayBuffer();
}